import React from 'react';

import Picture from '../../../common/Picture';
import Heading from '../common/Heading';
import task from '../../../img/icon-task.svg';
import multisport from '../../../img/landing/multisport.png';
import multisportWebp from '../../../img/landing/webp/multisport.webp';

const Prices = () => (
  <div className="u-polygon-bg u-bg-color-solitude">
    <div className="o-wrapper o-wrapper--large">
      <Heading
        subtitle="информация"
        title="цени и график"
        src={task}
        className="u-justify-content-center u-margin-bottom-large"
      />
      <div className="o-layout u-padding-vertical">
        <div className="o-layout__item u-1/1 u-1/2@md">
          <ul className="c-list--primary">
            <li className="c-list--primary__item">
              <div>1 посещение</div>
              <div>25 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div>8 посещения</div>
              <div>115 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div>12 посещения</div>
              <div>160 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div>16 посещения</div>
              <div>200 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div className="u-flex u-flex-column">
                <span>1 месец</span>
                <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                  (неограничен достъп)
                </span>
              </div>
              <div>230 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div className="u-flex u-flex-column">
                <span>1 посещение</span>
                <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                  (за членове на клуба)
                </span>
              </div>
              <div>20 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div className="u-flex u-flex-column">
                <span>1 посещение</span>
                <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                  (за тийнейджъри)
                </span>
              </div>
              <div>12 лв.</div>
            </li>
            <li className="c-list--primary__item">
              <div className="u-flex u-flex-column">
                <span>Карта за начинаещи</span>
                <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                  (8 посещения)
                </span>
              </div>
              <span>140 лв.</span>
            </li>
            <span className="u-font-italic u-weight-light u-font-size--small">
              Картите с определен брой посещения трябва да се използват в
              рамките на 45 дена
            </span>
          </ul>
          <section>
            <div className="o-box u-margin-top-large u-margin-bottom-large">
              <Picture
                src={multisport}
                srcWebp={multisportWebp}
                alt="MultiSport Logo"
                width="100px"
              />
              <p className="u-font-italic u-weight-light u-margin-top-small u-font-size--small">
                Картата покрива пълната цена на посещението.
              </p>
            </div>
          </section>
        </div>
        <div className="o-layout__item u-1/1 u-1/2@md">
          <h5 className="u-color-royal-blue u-text-center">
            Понеделник - Петък
          </h5>
          <div className="c-visiting-hours o-flx o-flx--justify-around u-border-bottom">
            <ul className="c-list--primary">
              <li className="u-padding-vertical-small">07:00 - 08:00</li>
              <li className="u-padding-vertical-small">08:00 - 09:00</li>
              <li className="u-padding-vertical-small">09:00 - 10:00</li>
              <li className="u-padding-vertical-small">10:00 - 11:00</li>
            </ul>
            <ul className="c-list--primary">
              <li className="u-padding-vertical-small">16:00 - 17:00</li>
              <li className="u-padding-vertical-small">17:00 - 18:00</li>
              <li className="u-padding-vertical-small">18:00 - 19:00</li>
              <li className="u-padding-vertical-small">19:00 - 20:00</li>
            </ul>
          </div>
          <h5 className="u-color-royal-blue u-margin-top-large u-text-center">
            Събота и Неделя
          </h5>
          <div className="c-visiting-hours o-flx o-flx--justify-around">
            <ul className="c-list--primary">
              <li className="u-padding-vertical-small">
                <div className="o-flx-col">
                  <span>09:00 - 10:00</span>
                  <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                    (Team WOD)
                  </span>
                </div>
              </li>
              <li className="u-padding-vertical-small">
                <div className="o-flx-col">
                  <span>10:00 - 11:00</span>
                  <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                    (Team WOD)
                  </span>
                </div>
              </li>
            </ul>
            <ul className="c-list--primary">
              <li className="u-padding-vertical-small">
                <div className="o-flx-col">
                  <span>11:00 - 12:00</span>
                  <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                    (Team WOD)
                  </span>
                </div>
              </li>
              <li className="u-padding-vertical-small">
                <div className="o-flx-col">
                  <span>12:30 - 14:00</span>
                  <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                    (Начинаещи)
                  </span>
                </div>
              </li>
              {/* <li className="u-padding-vertical-small">
                <div className="o-flx-col">
                  <span>14:00 - 15:00</span>
                  <span className="u-font-italic u-weight-light u-margin-top-tiny u-font-size--small">
                    (Деца)
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Prices;
